import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    posts: {},
    loading: true,
    firstload: false
  },
  getters:{

  },
  mutations: {
    updatePosts(state, posts) {
      state.posts = posts
    },
    changeLoadingState(state, loading) {
      state.loading = loading
    },
    changeFirstload(state){
      state.firstload = true
    }
  },
  actions: {
    loadData({commit}, payload) {
      commit('changeLoadingState', true);
      console.log("loading..."+payload);
      axios.get(`https://aprmay.co/data/data.php?season=${payload}`).then((response) => {
        // console.log(response.data, this)
        commit('updatePosts', response.data);
        commit('changeLoadingState', false);
        commit('changeFirstload');
      })
    },
  },
  modules: {
  }
})