import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from "axios";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;

Vue.prototype.$mySite = {
  title: "APR/MAY",
  url: "https://aprmay.co/",
  image: "https://aprmay.co/images/share.jpg" 
};

axios.defaults.baseURL = "https://aprmay.co/data/";

Vue.use(VueAxios, axios);

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

