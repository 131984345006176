<template>
  <div class="home">
 
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="collection"><div><router-link to="/season/3/1">SEASON III / <strong>ASPECTS</strong></router-link></div></div>
    <div class="logo-container"><div class="logo-div"><img class="home-logo" alt="aprmay.co" src="../assets/logo.svg"></div></div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  // components: {
  //   HelloWorld
  // }
   data() {
    return{
      hasMeta: {
        title: "Welcome to APR/MAY",
        description: "APR/MAY is a design duo exploring new ways of designing around bessarabian folk. The ethos of this creative tandem is to bring local folk in a new light through re-interpreting it in a contemporary yet timeless manner."
      }
    }
  },
  metaInfo() {
    return {
      title: this.$mySite.title,
      titleTemplate: this.hasMeta.title + " | %s",
      htmlAttrs: {
        lang: "en"
      },
      meta: [
        {
          name: "description",
          content: this.hasMeta.description
        },
        {
          property: "og:title",
          content: this.$mySite.title,
          vmid: "og:title"
        },
        {
          property: "og:description",
          content: this.hasMeta.description,
          vmid: "og:description"
        },
        {
          property: "og:image",
          content: this.$mySite.image,
          vmid: "og:image"
        },
        {
          property: "og:url",
          content: this.$mySite.url,
          vmid: "og:url"
        }
      ]
    };
  } //end meta
}
</script>

<style scoped>
.home {
  background-image: url(../assets/cover.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
}

.logo-container {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  z-index: 1;
  position: absolute;
}

.logo-div {
  margin-bottom: 0px;
}

.home-logo {
  max-width: 68vw;
  padding: 0;
  margin: 0;
  margin-bottom: -4px; 
}


.collection {
  font-size: 1rem;
  color: white;
  z-index: 2;
  margin-top:-10vh;
}

.collection a{
  color: white;
  text-decoration: none;
}

.collection a:hover{
  color: white;
  text-decoration: underline;
}
</style>
